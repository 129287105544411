import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import './calendar-pl.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

const calendar = $('#datepicker-winners').datepicker({
	clearBtn: false,
	language: "pl",
	maxViewMode: 0,
	startDate: new Date(2024, 4, 31),
	endDate: new Date(2024, 5, 30),
	defaultViewDate: {
		year: 2023,
		month: 0,
		day: 2
	},
});

calendar.on('changeDate', function (e) {

	$('section#winners ul[data-id=city]').empty();
	$('section#winners ul[data-id=technic]').empty();
	$('section#winners ul[data-id=friends]').empty();

	let date = new Date(e.date);
	let formattedDate = date.toLocaleDateString('pl-PL', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}).replace(/\./g, '-');
	formattedDate = formattedDate.split('-').reverse().join('-');

	$.ajax({
		url: '/winners',
		type: 'GET',
		data: {
			date: formattedDate
		},
		success: function (data) {
            if (data.winners.length > 0) {
                let winners = '';
                data.winners.forEach(function (winner) {
                    winners += `${winner.name} <br/>`;
                });

                $('.without-winner').addClass('d-none');
                $('.with-winner').removeClass('d-none');
                $('.with-winner').html(winners);
            } else {
                $('.current-date').text(formattedDate = formattedDate.split('-').reverse().join('.'));
                $('.without-winner').removeClass('d-none');
                $('.with-winner').addClass('d-none');
                $('.with-winner').text('');
            }
		},
		error: function (data) {
			console.log(data);
		}
	});
});

// $('.datepicker').datepicker({
// 	format: "yyyy-mm-dd",
// 	clearBtn: true,
// 	language: "pl",
// });

// $('.datepicker-birthday').datepicker({
// 	format: "yyyy-mm-dd",
// 	clearBtn: true,
// 	language: "pl",
// 	maxViewMode: 3,
// 	startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 120)),
// 	endDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
// 	defaultViewDate: {
// 		year: new Date().getFullYear() - 18,
// 		month: 0,
// 		day: 1
// 	},
// });
//
// $('.datepicker-purchase').datepicker({
// 	format: "yyyy-mm-dd",
// 	clearBtn: true,
// 	language: "pl",
// 	maxViewMode: 0,
// 	startDate: new Date(2023, 4, 2),
// 	endDate: new Date(2023, 5, 26),
// 	defaultViewDate: {
// 		year: 2023,
// 		month: 0,
// 		day: 2
// 	},
// });